import { render, staticRenderFns } from "./TaskCreate.vue?vue&type=template&id=3b0c19ca&scoped=true"
import script from "./TaskCreate.vue?vue&type=script&lang=js"
export * from "./TaskCreate.vue?vue&type=script&lang=js"
import style0 from "./TaskCreate.vue?vue&type=style&index=0&id=3b0c19ca&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0c19ca",
  null
  
)

export default component.exports