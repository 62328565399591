<template>
  <div>
    <BasicEditContainer
      :title="t('title')"
      ref="editor"
      :object-type="t('details.object-type')"
      :object-name="dataLoaded ? customerUser.user.display_name : ''"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges"
      :delete-action="deleteItem"
      @deleted="close"
      :allow-delete="!isImportProtected"
      :button-text="t('details.remove-contact')"
    >
      <template v-slot:default>

        <v-card elevation="0">
          <v-card-title>{{ t('details.card-title') }}</v-card-title>
          <v-card-text v-if="dataLoaded">

            <v-text-field
                :label="t('details.name')"
                v-model="customerUser.user.display_name"
                filled
                :disabled="true"
                hide-details="auto"
            ></v-text-field>

            <v-checkbox
              v-model="customerUser.is_customer_admin"
              :label="t('details.customer-admin')"
              hide-details
              class="shrink mr-0 mt-2"
              :ripple="false"
              :disabled="true"
            ></v-checkbox>

          </v-card-text>
        </v-card>
        
        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('employees.card-title') }}</v-card-title>
          <v-card-text v-if="dataLoaded">
            
            <div class="d-flex justify-end sp-mar-bot-2">
              <v-btn
                color="primary"
                elevation="0"
                class="icon-left"
                @click="showAddUserDialog()"
                :disabled="isImportProtected"
                :title="getImportProtectedMessage()"
              >
                <v-icon>mdi-plus</v-icon>
                {{ t('employees.add') }}
              </v-btn>
            </div>
            
            <v-data-table
              :headers="headers"
              :items="customerUser.employees"
              class="elevation-0 mb-0"
              hide-default-footer
            >            
              <template v-slot:[`item.name`]="{ item }">
                <span class="ellipsis">{{ item.user ? item.user.display_name : item.display_name }}</span>
              </template>

              <template v-slot:[`item.number`]="{ item }">
                {{ item.user ? item.user.number : item.number }}
              </template>
    
              <template v-slot:[`item.username`]="{ item }">
                {{ item.user ? item.user.username : item.username }}
              </template>
    
              <template v-slot:[`item.role`]="{ item }">
                {{ getUserRoleName(item) }}
              </template>

              <template v-slot:[`item.delete`]="{ item, index }">
                <v-icon @click="removeUser(index)" class="delete-handle">mdi-trash-can-outline</v-icon>
              </template>
            </v-data-table>

          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>
        
        <v-card elevation="0">
          <v-card-title>{{ t('details.customer.card-title') }}</v-card-title>
          <v-card-text v-if="dataLoaded">

            <v-text-field
                :label="t('details.customer.name')"
                v-model="customerUser.customer.name"
                filled
                :disabled="true"
                hide-details="auto"
            ></v-text-field>

          </v-card-text>
        </v-card>

        <v-card v-if="dataLoaded && customerUser.is_customer_admin" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('details.inherited.card-title') }}</v-card-title>
          <v-card-text>

            <div v-if="inheritedUsers.length > 0" class="inherited-users">
              <div v-for="(iu, index) in inheritedUsers" :key="index" class="inherited-user">
                <span>{{ iu.user.display_name }}</span>
                <span><v-icon small>mdi-account-tie</v-icon>{{ iu.customer_user.user.display_name }}</span>
              </div>
            </div>
            <div v-else>
              <i>{{ t('details.inherited.none') }}</i>
            </div>

          </v-card-text>
        </v-card>

      </template>
    </BasicEditContainer>

    <v-dialog
      v-model="addUserDialog"
      content-class="add-customer-user-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('details.dialog.title') }}
        </v-card-title>
        <v-card-text class="px-4">
          <div v-if="loadingUsers">
            <v-progress-circular
              :width="2"
              :size="24"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-text-field
              elevation="0"
              :label="t('details.dialog.user-search')"
              v-model="userSearchValue"
              append-icon="mdi-magnify"
              filled
              hide-details="auto"
            ></v-text-field>
            <div class="dialog-user-entry dialog-user-select-all">
              {{ t('details.dialog.select-all') }}
              <v-checkbox
                v-model="selectAll"
                hide-details
                class="shrink mr-0 mt-0"
                :ripple="false"
                @change="checkAll($event)"
              ></v-checkbox>
            </div>
            <div class="filtered-user-wrapper">
              <div v-for="(fu, index) in filteredUsers"
                :key="index"
                class="dialog-user-entry">
                <span>{{ fu.name + ' ' + fu.firstname }}</span>
                <v-checkbox
                  v-model="fu.selected"
                  hide-details
                  :ripple="false"
                  class="shrink mr-0 mt-0"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hideAddUserDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('details.dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            :loading="isAddingUsers"
            @click="addUsers"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('details.dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'

export default {
  name: "CustomerContactsEdit",
  components: {
    BasicEditContainer
  },
  data() {
    return {
      customerId: null,
      userId: null,
      customerUser: null,
      isImportProtected: false,
      itemOriginal: null,
      dataLoaded: false,
      addUserDialog: false,
      loadingUsers: false,
      customerUsers: [],
      inheritedUsers: [],
      existingUsers: [],
      users: [],
      userSearchValue: '',
      isAddingUsers: false,
      isRemovingUsers: false,
      selectAll: false,
      isCustomerAdmin: false,
    }
  },
  async created() {
    this.customerId = this.$route.params.id
    this.userId = this.$route.params.userid
    this.user = store.get('user')
    this.getCustomerUsers()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.customerUser) !== JSON.stringify(this.itemOriginal)
    },
    headers() {
      let headers = [
        {
          text: this.$t('users.list.table.name'),
          sortable: false,
          value: 'name',
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.username'),
          value: 'username',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.role'),
          value: 'role',
          sortable: false,
          cellClass: 'text-middle',
          width: '75'
        }
      ]

      if (!this.isImportProtected) {
        headers.push(
          {
            text: '',
            value: 'delete',
            sortable: false,
            cellClass: 'text-middle',
            width: '75'
          }
        )
      }

      return headers
    },
    filteredUsers() {
      if (this.userSearchValue !== '') {
        return this.users.filter((user) =>
          user.display_name.toLowerCase().includes(this.userSearchValue.toLowerCase())
        )
      } else {
        return this.users
      }
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customer-users.edit.' + key)
    },
    getCustomerUsers() {
      HTTP.get('customers/' + this.customerId + '/users')
        .then(function (response) {
          this.customerUsers = response.data.data
          if (this.user.role === 'admin') {
            this.isCustomerAdmin = true
          } else {
            let user = this.customerUsers.filter(u => u.id === this.user.id)
            if (user.length === 1) {
              this.isCustomerAdmin = !!user.is_customer_admin
            }
          }
          this.getCustomerUser()
        }.bind(this))
    },
    getCustomerUser() {
      HTTP.get('customers/' + this.customerId + '/users/' + this.userId).then(function (response) {
        this.customerUser = response.data
        this.setExistingUsers()
        this.itemOriginal = JSON.parse(JSON.stringify(this.customerUser))
        if (this.customerUser.is_customer_admin) {
          this.customerUsers.forEach((cu) => {
            if (cu.id !== this.customerUser.id) {
              this.inheritedUsers.push(...cu.employees)
            }
          })
        }
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {    
        console.log(error)
      }.bind(this))
    },
    getUsers() {
      this.loadingUsers = true
      HTTP.get('users?onlyActive=true').then(function (response) {
        this.users = response.data.filter(u => !this.existingUsers.includes(u.id))
        this.users.forEach((user) => {
            this.$set(user, 'selected', false)
        })
        this.loadingUsers = false
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.loadingUsers = false
      }.bind(this))
    },
    addUsers() {
      let usersToAdd = this.users.filter((u) => u.selected)
      this.customerUser.employees.push(...usersToAdd)
      this.setExistingUsers()
      this.hideAddUserDialog()
      this.selectAll = false
      this.userSearchValue = ''
    },
    removeUser(index) {
      this.customerUser.employees.splice(index, 1)
      this.setExistingUsers()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: 'CustomersContactsIndex', params: { id: this.customerId } })
    },
    deleteItem() {
      return HTTP.delete('customers/' + this.customerId + '/user/' + this.userId + '/remove')
    },
    saveItem() {
      const json = JSON.stringify({
        users: this.customerUser.employees
      })
      return HTTP.post('customers/' + this.customerId + '/user/' + this.userId + '/employees/add', { 'data': json })
    },
    onSaved() {
      this.close()
    },
    checkAll(checkAll) {
      this.filteredUsers.forEach((fu) => {
        fu.selected = checkAll
      })
    },
    setExistingUsers() {
      this.existingUsers = this.customerUser.employees.map((eu) => eu.user_id || eu.id)
    },
    getUserRoleName(item) {
      if (item.user) {
        return this.$t('users.data.roles.' + item.user.role)
      } else {
        return this.$t('users.data.roles.' + item.role)
      }
    },
    showAddUserDialog() {
      this.getUsers()
      this.addUserDialog = true
    },
    hideAddUserDialog() {
      this.addUserDialog = false
    },
    getImportProtectedMessage() {
      return this.isImportProtected ? this.$t('customer-projects.import-protected') : ''
    }
  }
}
</script>

<style scoped lang="scss">
.user-profile-image {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.30);
}

.user-placeholder i {
  display: block;
  font-size: 30px;
  line-height: 36px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: rgba(0, 0, 0, 0.30);
}

.user-imported {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 40px;
  border-radius: 100%;
  border: 1px solid #999;
  background: #FFF;
  overflow: hidden;

  i {
    color: #999;
  }
}

.add-customer-user-dialog {

  .v-progress-circular {
    display: block;
    margin: 0 auto;
    min-height: 100px;
  }

  .dialog-user-entry {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    line-height: 28px;
  
    &:not(.dialog-user-select-all):hover {
      background-color: #F5F5F5;
    }
  
    &.dialog-user-select-all {
      padding: 10px;
      border-bottom: 1px solid #E0E0E0;
    }
  
    span:first-child {
      max-width: calc(100% - 40px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .v-input {
      position: relative;
      top: -2px;
      right: -13px;
    }
  }

  .filtered-user-wrapper {
    max-height: calc(100vh - 460px);
    margin: 10px 0;
    overflow: hidden;
    overflow-y: auto;
  }
}

.inherited-users {
  display: flex;
  gap: 8px;
  flex-direction: column;

  .inherited-user {
    padding: 8px 8px 4px 8px;
    background: #F5F5F5;
    border-radius: 4px;

    & > span:first-child {
      display: block;
      font-size: 16px;
      color: black;
    }

    & > span:last-child i {
      vertical-align: text-top;
      margin-right: 2px;
    }
  }
}
</style>
