<template>
  <v-dialog
      v-model="dialog"
      content-class="delete-dialog"
      width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          elevation="0"
          color="error"
          class="delete icon-left"
          data-cy="deleteButton"
          v-bind="attrs"
          v-on="on"
          :loading="processing"
          :disabled="disabled"
      >
        <v-icon>mdi-delete</v-icon>
        {{ buttonTextValue }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ question }}
      </v-card-title>

      <v-card-text>
        {{ message }}
        <v-alert
          v-if="deletionHintText && deletionHintText !== ''"
          class="deletion-hint"
          color="warning"
          icon="mdi-information"
          outlined
        >
          {{ deletionHintText }}
          <ul v-if="deletionHintList.length > 0">
            <li v-for="(deletionHintListEntry, index) in deletionHintList.slice(0, 10)" :index="index">
              {{ deletionHintListEntry }}
            </li>
            <li v-if="deletionHintList.length > 10">
              ...
            </li>
          </ul>
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
          {{ $t('components.delete-button.cancel') }}
        </v-btn>
        <v-btn
            color="error"
            elevation="0"
            class="delete"
            data-cy="deleteConfirmButton"
            @click="doDelete()"
        >
          <v-icon>mdi-check</v-icon>
          {{ $t('components.delete-button.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteButton",
  props: {
    objectType: String,
    objectName: String,
    processing: {
      type: Boolean,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    deletionHintText: String,
    deletionHintList: {
      type: Array,
      default: () => []
    },
    buttonText: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    question() {
      return this.$t('components.delete-button.question-title', { type: this.objectType });
    },
    message() {
      return this.$t('components.delete-button.question-message', { type: this.objectType, name: this.objectName });
    },
    buttonTextValue() {
      return this.buttonText ? this.buttonText : this.$t('components.delete-button.label')
    }
  },
  methods: {
    doDelete() {
      this.dialog = false;
      this.$emit('click');
    }
  }
}
</script>

<style scoped lang="scss">
.delete {
  float: right;
  margin: 0;
}
.deletion-hint {
  margin-bottom: 0;
  margin-top: 12px;

  ul {
    margin: 0;
    margin-top: 8px;
  }
}
</style>
