<template>
  <div>
    <v-card elevation="0">
      <v-card-title>
        {{ $t('components.customer-map-select.label') }}
      </v-card-title>
      <v-card-text>
        <v-row align="center">
          <v-col cols="12" class="d-flex">

            <GmapAutocomplete
              ref="gmapAutocomplete"
              class="gmap-search"
              :placeholder="$t('components.customer-map-select.location-input')"
              @place_changed="onLocationSelected"
              :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
              id="gmap-search-input"
            >
              <template v-slot:input="slotProps">
                <v-text-field
                  outlined
                  placeholder="Location Of Event"
                  ref="input"
                  v-on:listeners="slotProps.listeners"
                  v-on:attrs="slotProps.attrs">
                </v-text-field>
              </template>
            </GmapAutocomplete>

            <v-btn
              elevation="0"
              color="primary"
              outlined
              height="46px"
              class="ml-3"
              @click="clearLocationSearchField()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              filled
              :label="$t('components.customer-map-select.radius')"
              v-model.number="data.radius"
              :rules="[val => Number.isInteger(val) && val > 0 || $t('components.customer-map-select.validation.radius')]"
              @keyup="$emit('setCustomerLocation', data)"
              data-cy="radius"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field data-cy="latitude" filled :label="$t('components.customer-map-select.latitude')" v-model.number="data.location.lat"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field data-cy="longitude" filled :label="$t('components.customer-map-select.longitude')" v-model.number="data.location.lng"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <GmapMap
              ref="mapRef"
              :center="{lat: 47.3778579, lng: 8.5403226}"
              :zoom="17"
              map-type-id="terrain"
              :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false
              }"
              style="width: 100%; height: 350px"
            >
              <GmapMarker
                v-if="selectedPlace.lat != null"
                :icon="markerOptions"
                :draggable="true"
                :position="{
                  lat: selectedPlace.lat,
                  lng: selectedPlace.lng
                }"
                @dragend="setPosition"
              />
              <GmapCircle
                v-if="data.location.lat != null"
                :center="{lat: data.location.lat, lng: data.location.lng}"
                :radius="data.radius"
                :visible="true"
                :options="{fillColor:'#009d85',fillOpacity:0.4,strokeColor:'#009d85',strokeWeight:2}"
              ></GmapCircle>
            </GmapMap>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

const mapMarker = require('../assets/images/map-marker.png')

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAN5sUA-UFqoeI2J1Zru9NYv3QaETjMiCY',
    libraries: 'places'
  }
})

export default {
  name: "CustomerMapSelect",
  components: {},
  emits: ['close'],
  props: {
    show: Boolean,
    initLocation: Object,
  },
  data() {
    return {
      dialog: false,
      selectedPlace: {
        geometry: null,
      },
      data: {
        location: {
          lat: null,
          lng: null,
        },
        radius: 50,
      },
      markerOptions: {
        url: mapMarker
      },
    };
  },
  watch: {
    initLocation: function (val) {
      this.setPosition(val)
      this.$refs.mapRef.$mapPromise.then((map) => {
        if (map != null && val.location.lat != null) {
          map.panTo({
            lat: val.location.lat,
            lng: val.location.lng
          })
        }
      })
    },
    'data.location.lat': function (val)  {
      this.$emit('setCustomerLocation', this.data)
    },
    'data.location.lng': function (val)  {
      this.$emit('setCustomerLocation', this.data)
    },
    'data.radius': function (val)  {
      this.$emit('setCustomerLocation', this.data)
    }
  },
  mounted() {
    if(this.initLocation) {
      this.setPosition(this.initLocation)
      this.panMapAfterLoad()
    } else {
      this.clearPosition()
    }
  },
  methods: {
    save() {
      this.$emit('setCustomerLocation', this.data)
    },
    panMapAfterLoad() {
      setTimeout(() => {
        if (this.$refs.mapRef != null && this.data.location.lat != null)
          this.$refs.mapRef.$mapObject.panTo({ lat: this.data.location.lat, lng: this.data.location.lng })
      }, 1000)
    },
    onLocationSelected(place) {
      this.selectedPlace = place
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({
          lat: this.selectedPlace.geometry.location.lat(),
          lng: this.selectedPlace.geometry.location.lng()
        })
      })
      this.data.location.lat = this.selectedPlace.geometry.location.lat()
      this.data.location.lng = this.selectedPlace.geometry.location.lng()
    },
    clearPosition() {
      this.selectedPlace = {}
      this.clearLocationSearchField()
      this.data.location.lat = null
      this.data.location.lng = null
      this.$refs.mapRef.$mapPromise.then((map) => {
        map.panTo({ lat: 47.3778579, lng: 8.5403226 })
      })
    },
    close() {
      this.$emit('close')
    },
    setPosition(data) {
      this.selectedPlace = {}
      this.clearLocationSearchField()
      this.data.location.lat = data.location.lat
      this.data.location.lng = data.location.lng
      this.data.radius = data.radius
    },
    clearLocationSearchField() {
      if (this.$refs && this.$refs.gmapAutocomplete) {
        this.$refs.gmapAutocomplete.$refs.input.value = ''
      }
    }
  }
}
</script>

<style scoped>
.gmap-search {
  width: 100%;
  background: #F5F5F5 !important;
  box-shadow: none !important;
  border-radius: 4px;
  padding: 12px;
}
</style>
