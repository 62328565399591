<template>
  <div>
    <BasicNewContainer
      :title="t('title')"
      ref="editor"
      @cancel="close"
      :saveAction="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              :label="t('details.customer')"
              v-model="customerName"
              filled
              :disabled="true"
            ></v-text-field>

            <v-text-field
              :label="t('details.name') + '*'"
              counter="120"
              v-model="item.name"
              data-cy="name"
              filled
              :rules="[
                () => !!item.name || $t('validation.required.name'),
                () => (item.name && item.name.length <= 120) || generateLengthMessage(t('details.name'), 120)
              ]"
            ></v-text-field>

            <v-text-field
              :label="t('details.cost-center')"
              counter="120"
              v-model="item.cost_center"
              data-cy="cost_center"
              filled
            ></v-text-field>

            <v-text-field
              :label="t('details.purchase-document')"
              counter="120"
              v-model="item.purchase_document"
              data-cy="purchase_document"
              filled
            ></v-text-field>

            <v-checkbox
              v-model="item.time_type"
              class="mt-0"
              :label="t('details.time_type')"
              hide-details
            >
            </v-checkbox>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('status.group-title') }}</v-card-title>
          <v-card-text>

            <v-switch
              v-model="item.is_active"
              inset
              :label="t(item.is_active ? 'status.active': 'status.inactive')"
            ></v-switch>

          </v-card-text>
        </v-card>

      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import { generateLengthMessage } from '@/mixins/validationMessages'

export default {
  name: "CustomerProjectCreate",
  components: { BasicNewContainer },
  data() {
    return {
      customerId: null,
      customer: null,
      itemOriginal: null,
      item: {
        customer_id: null,
        name: null,
        cost_center: '',
        is_active: true,
        purchase_document: '',
        time_type: false
      }
    }
  },
  created() {
    this.customerId = this.$route.params.id
    this.loadData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    customerName() {
      if (this.dataLoaded) {
        if (this.customer.private != null && this.customer.private.firstname != null) {
          return this.customer.private.firstname + ' ' + this.customer.private.lastname;
        }
        if (this.customer.company != null && this.customer.company.name != null) {
          return this.customer.company.name;
        }
      }
      return ''
    }
  },
  methods: {
    generateLengthMessage,
    t: function (key) {
      return this.$t('customer-projects.detail.' + key)
    },
    loadData() {
      HTTP.get('/customers/' + this.customerId).then(function (response) {
        this.customer = response.data
        this.item.customer_id = this.customer.id
        this.itemOriginal = JSON.parse(JSON.stringify(this.item))
      }.bind(this))
    },
    saveItem() {
      return HTTP.post('customer-projects', this.item)
    },
    onSaved() {
      this.close()
    },
    close() {
      this.$router.push({name: 'CustomersProjectsIndex', params: { id: this.customerId }})
    }
  }
}
</script>

<style scoped lang="scss"></style>
