<template>
  <div>
    <div>
      <toolbar :dataLoaded="true">{{ t('title') + customerName }}</toolbar>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              outlined
              plain
              :to="{ name: 'CustomersEdit', params: { id: this.customerId } }"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ t('back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              color="primary"
              class="icon-left"
              data-cy="createButton"
              :to="{ name: 'CustomerProjectCreate', params: { id: this.customerId } }"
              :disabled="isImportProtected"
              :title="getImportProtectedMessage()"
          >
            <v-icon>{{ isImportProtected ? 'mdi-lock-outline' : 'mdi-plus' }}</v-icon>
            {{ t('create') }}
          </v-btn>
        </v-row>

        <v-row v-if="!loading && items.length === 0">
          <div class="empty-datatable">
            <v-icon>mdi-alert-circle-outline</v-icon>
            {{ t('no-data') }}
          </div>
        </v-row>

        <v-row v-else>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis">{{ item.name }}</span>
            </template>

            <template v-slot:[`item.cost-center`]="{ item }">
              {{ item.cost_center }}
            </template>

            <template v-slot:[`item.project-users`]="{ item }">
              {{ item.project_users.length }}
            </template>

            <template v-slot:[`item.status`]="{ item }">
              {{
                item.is_active
                    ? $t('customer-projects.data.status.active')
                    : $t('customer-projects.data.status.inactive')
              }}
            </template>

            <template v-slot:[`item.details`]="{ item }">
              <router-link :to="{ name: 'CustomerProjectEdit', params: { id: item.id  } }" data-cy="editButton">
                <v-icon small class="edit-row-button">{{ isImportProtected ? 'mdi-eye-outline' : 'edit' }}</v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import toolbar from '@/components/layouts/Navigation'

export default {
  name: "CustomersProjectsIndex",
  components: {
    toolbar
  },
  data() {
    return {
      customerId: null,
      customer: null,
      loading: false,
      items: [],
      isImportProtected: false,
    }
  },
  created() {
    this.customerId = this.$route.params.id
    this.getCustomerProjects()
  },
  computed: {
    customerName() {
      if (this.dataLoaded && this.customer != null) {
        if (this.customer.name == null) {
          if (this.customer.firstname != null && this.customer.lastname != null) {
            return ' : ' + this.customer.firstname + ' ' + this.customer.lastname
          }
        } else {
          return ' : ' + this.customer.name
        }
      }
      return ''
    },
    headers() {
      let headers = [
        {
          text: this.t('table.project-name'),
          align: 'start',
          sortable: false,
          value: 'name',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.cost-center'),
          align: 'start',
          sortable: false,
          value: 'cost-center',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.assigned-contacts'),
          align: 'center',
          sortable: false,
          value: 'project-users',
          cellClass: 'text-middle'
        },
        {
          text: this.t('table.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          cellClass: 'text-middle'
        },
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'details',
          width: 75
        }
      ]

      return headers
    },
  },
  methods: {
    t: function (key) {
      return this.$t('customer-projects.list.' + key)
    },
    getCustomerProjects() {
      this.loading = true
      HTTP.get('customers/' + this.customerId + '/projects').then(function (response) {
        this.items = response.data.data
        this.customer = response.data.customer
      }.bind(this)).finally(() => {
        this.loading = false
      })
    },
    getImportProtectedMessage() {
      return this.isImportProtected ? this.$t('customer-projects.import-protected') : ''
    }
  }
}
</script>

<style scoped lang="scss">
.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
