<template>
  <div>
    <BasicEditContainer
      :title="t('edit.title')"
      ref="editor"
      object-type="Customer"
      :object-name="item.name"
      :data-loaded="dataLoaded"
      @cancel="cancel"
      :save-action="saveItem"
      @saved="onSaved"
      :has-changes="hasChanges"
      :delete-action="deleteItem"
      @deleted="close"
      :allow-delete="true"
    >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <customer-edit-company v-if="isCompany" v-model="item.company"/>
            <customer-edit-private v-if="isPrivate" v-model="item.private"/>
          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('picture.title') }}</v-card-title>
          <v-card-text>
            <PictureUpload v-model="item.profile_picture" aspectMode="quadratic"/>
          </v-card-text>
        </v-card>

        <v-card v-if="isCompany && item.company.contact" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('contact-person.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
              v-model="item.company.contact.salutation"
            />
            <v-text-field
              :label="t('contact-person.firstname')"
              v-model="item.company.contact.firstname"
              data-cy="firstname"
              filled
            />
            <v-text-field
              :label="t('contact-person.lastname')"
              v-model="item.company.contact.lastname"
              data-cy="lastname"
              filled
            />
            <v-text-field
              :label="t('contact-person.email')"
              v-model="item.company.contact.email"
              data-cy="contact_email"
              filled
            />
            <v-text-field
              :label="t('contact-person.phone')"
              v-model="item.company.contact.phone"
              data-cy="mobile"
              filled
            />
          </v-card-text>
        </v-card>

      </template>

      <template v-slot:meta>
        <v-card v-if="item" elevation="0">
          <v-card-title>{{ t('details.qrcode') }}</v-card-title>
          <v-card-text class="text-center">
            <qrcode :value="item.id" :options="{width:200}"></qrcode>
          </v-card-text>
        </v-card>

        <v-card v-if="item" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('details.timemanagement') }}</v-card-title>
          <v-card-text>
            <p>{{ t('details.timemanagement-description') }}</p>
            <v-switch
              class="mt-2"
              v-model="item.is_time_management"
              inset
              :true-value="1"
              :false-value="0"
              :label="item.is_time_management ? t('details.yes') : t('details.no')"
            ></v-switch>
          </v-card-text>
        </v-card>

        <CustomerMapSelect
          class="sp-mar-top-3"
          ref="map"
          :initLocation="currentCustomerLocation"
          @save="saveCustomerLocation"
          @setCustomerLocation="setCustomerLocation"
        ></CustomerMapSelect>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('users.title') }}</v-card-title>
          <v-card-text>
            <div v-if="!isImportProtected">
              <content-filter-employee ref="cfe" :label="t('users.search')" @item-selected="userSelected" />
            </div>

            <div class="search-items" v-for="(item, index) in users" :key="item.id"
                  @click="selectItem('users', item)" v-if="searchUsers !== ''">
                  {{ item.display_name }}
            </div>

            <div v-for="(item, index) in item.assigned.users" :key="item.id" class="search-items item-selected-inline search-multi">
              <v-icon v-if="item.pivot && item.pivot.is_customer_admin" color="white" class="mr-2">mdi-account-supervisor</v-icon>
              <span>{{ item.display_name }}</span>
              <v-icon @click.native="removeItem('users', index)" color="white" class="ml-2">mdi-close-circle-outline</v-icon>
            </div>
          </v-card-text>
        </v-card>

        <v-btn
          color="primary"
          elevation="0"
          class="icon-left action-button sp-mar-top-3"
          outlined
          x-large
          data-cy="projectButton"
          :to="{ path: '/customers/' + itemId + '/projects' }"
        >
          <v-icon>mdi-forward</v-icon>
          {{ t('manage-projects') }}
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="icon-left action-button sp-mar-top-1"
          outlined
          x-large
          data-cy="customerButton"
          :to="{ path: '/customers/' + itemId + '/users' }"
        >
          <v-icon>mdi-forward</v-icon>
          {{ t('manage-contacts') }}
        </v-btn>

      </template>
    </BasicEditContainer>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import _ from 'lodash'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import qrcode from '@chenfengyuan/vue-qrcode'
import CustomerMapSelect from '@/components/CustomerMapSelect'
import SalutationSelect from '@/components/SalutationSelect'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import PictureUpload from '@/components/inputs/PictureUpload'
import CustomerEditPrivate from '@/views/CustomerEditPrivate'
import CustomerEditCompany from '@/views/CustomerEditCompany'

export default {
  name: "CustomerEdit",
  components: {
    CustomerEditCompany,
    CustomerEditPrivate,
    BasicEditContainer,
    ContentFilterEmployee,
    CancelButtonDialog,
    CustomerMapSelect,
    qrcode,
    SalutationSelect,
    AppFilterDatePicker,
    PictureUpload
  },
  data() {
    return {
      itemId: null,
      item: {
        id: null,
        profile_picture: '',
        assigned: {
          users: []
        },
        address_type: 'company',
        company: {
          name: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
          contact: {},
          extension_fields: {}
        },
        private: {
          salutation: '',
          firstname: '',
          lastname: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
        },
        location: {
          latitude: null,
          longitude: null,
          radius: null,
        },
        project_id: '',
        contact_user: {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
        }
      },
      isImportProtected: false,
      searchUsers: '',
      users: '',
      itemOriginal: null,
      dataLoaded: false,
      currentCustomerLocation: null,
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  watch: {
    searchUsers: function (val) {
      this.getSearchResultUsers(encodeURIComponent(val))
    }
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false;
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    readonly() {
      return this.item.ext_id
    },
    isCompany() {
      return this.item.address_type === 'company'
    },
    isPrivate() {
      return this.item.address_type === 'private'
    },
  },
  methods: {
    t: function (key) {
      return this.$t('customers.detail.' + key)
    },
    loadData() {
      HTTP.get('customers/' + this.itemId).then(function (response) {
        this.item = response.data
        this.itemOriginal = JSON.parse(JSON.stringify(this.item))

        // set init Location for map select if data exists
        if (this.itemOriginal.location) {
          this.currentCustomerLocation = {
            location: {
              lat: this.itemOriginal.location.latitude,
              lng: this.itemOriginal.location.longitude,
            },
            radius: this.itemOriginal.location.radius
          }
        }
        this.dataLoaded = true
      }.bind(this)).catch(function (error) {    
        console.log(error)
      }.bind(this))
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({ name: 'Customers' })
    },
    deleteItem() {
      return HTTP.delete('customers/' + this.itemId)
    },
    saveItem() {
      const data = {...this.item}
      return HTTP.patch('customers/' + this.item.id, data)
    },
    setCustomerLocation(data) {
      this.item.location.latitude = data.location.lat
      this.item.location.longitude = data.location.lng
      this.item.location.radius = data.radius
    },
    async saveCustomerLocation(data) {
      //
    },
    onSaved() {
      this.close()
    },
    userSelected(id, user) {
      if (id) {
        this.item.assigned.users.push(user)
        this.checkDuplicates('users')
        this.$refs.cfe.$refs.autocomplete.reset()
      }
    },
    checkDuplicates(type) {
        this.item.assigned[type] = _.uniqBy(this.item.assigned[type], 'id')
    },
    removeItem(type, index) {
        this.item.assigned[type].splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.action-button {
  width: 100%;
}

.md-theme-default.md-input-disabled {
  color: rgba(0, 0, 0, .38);
}

.disabled-input {
  margin-bottom: 24px;
  font-size: 16px;

  label {
    line-height: 24px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
  }

  span {
    display: block;

    &.small {
      font-size: 14px;
    }

  }
}

.search-items.item-selected-inline {
  width: 100%;
  margin-right: 0;

  span {
    position: relative;
    top: 1px;
  }

  .v-icon.mdi-close-circle-outline {
    float: right;
  }
}
</style>
