<template>
  <div>
    <basic-new-container
        :title="t('create.title')"
        ref="editor"
        @cancel="cancel"
        :saveAction="saveItem"
        @saved="onSaved"
        :has-changes="hasChanges"
      >
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <div class="mb-4">
              {{ t('address-type.title') }}
              <v-radio-group v-model="item.address_type" data-cy="address_type">
                <v-radio
                  :label="t('address-type.company')"
                  value="company"
                  data-cy="company"
                />
                <v-radio
                  :label="t('address-type.private')"
                  value="private"
                  data-cy="private"
                />
              </v-radio-group>
            </div>

            <customer-edit-company v-if="isCompany" v-model="item.company"/>
            <customer-edit-private v-if="isPrivate" v-model="item.private"/>

          </v-card-text>
        </v-card>

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('picture.title') }}</v-card-title>
          <v-card-text>
            <PictureUpload v-model="item.profile_picture" aspectMode="quadratic"/>
          </v-card-text>
        </v-card>

        <v-card v-if="isCompany" elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('contact-person.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
              v-model="item.company.contact.salutation"
              data-cy="salutation"
            />
            <v-text-field
              :label="t('contact-person.firstname')"
              v-model="item.company.contact.firstname"
              data-cy="firstname"
              filled
            />
            <v-text-field
              :label="t('contact-person.lastname')"
              v-model="item.company.contact.lastname"
              data-cy="lastname"
              filled
            />
            <v-text-field
              :label="t('contact-person.email')"
              v-model="item.company.contact.email"
              data-cy="contact_email"
              filled
            />
            <v-text-field
              :label="t('contact-person.phone')"
              v-model="item.company.contact.phone"
              data-cy="mobile"
              filled
            />
          </v-card-text>
        </v-card>
      </template>
      
      <template v-slot:meta>
        <v-card v-if="item" elevation="0">
          <v-card-title>{{ t('details.timemanagement') }}</v-card-title>
          <v-card-text>
            <p>{{ t('details.timemanagement-description') }}</p>
            <v-switch
              class="mt-2"
              v-model="item.is_time_management"
              inset
              :true-value="1"
              :false-value="0"
              :label="item.is_time_management ? t('details.yes') : t('details.no')"
            ></v-switch>
          </v-card-text>
        </v-card>

        <customer-map-select
          class="sp-mar-top-3"
          ref="map"
          :initLocation="currentCustomerLocation"
          @save="saveCustomerLocation"
          @setCustomerLocation="setCustomerLocation"
        />

        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('users.title') }}</v-card-title>
          <v-card-text class="text-center">
            <div v-if="!isImportProtected">
              <content-filter-employee ref="cfe" :label="t('users.search')" @item-selected="userSelected" />
            </div>

            <div class="search-items" v-for="(item, index) in users" :key="item.id"
                  @click="selectItem('users', item)" v-if="searchUsers !== ''">
                  {{ item.display_name }}
            </div>

            <div v-for="(item, index) in item.assigned.users" :key="item.id" class="search-items item-selected-inline search-multi">
              <span>{{ item.display_name }}</span>
              <v-icon @click.native="removeItem('users', index)" color="white" class="ml-2">remove_circle</v-icon>
            </div>
          </v-card-text>
        </v-card>

      </template>
    </basic-new-container>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import _ from 'lodash'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import ContentFilterEmployee from '@/components/filter-components/ContentFilterEmployee'
import CustomerMapSelect from '@/components/CustomerMapSelect'
import SalutationSelect from '@/components/SalutationSelect'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import PictureUpload from '@/components/inputs/PictureUpload'
import CustomerEditCompany from '@/views/CustomerEditCompany'
import CustomerEditPrivate from '@/views/CustomerEditPrivate'

export default {
  name: "CustomersCreate",
  components: {
    CustomerEditPrivate,
    CustomerEditCompany,
    CustomerMapSelect,
    BasicNewContainer,
    SalutationSelect,
    AppFilterDatePicker,
    PictureUpload,
    ContentFilterEmployee
  },
  data() {
    return {
      item: {
        id: null,
        address_type: 'company',
        assigned: {
          users: []
        },
        company: {
          name: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
          contact: {
            salutation: '',
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
          },
          extension_fields: {
            email: '',
            phone: '',
            notes: '',
          },
        },
        private: {
          salutation: '',
          firstname: '',
          lastname: '',
          address: {
            address_display: null,
            address_line_1: '',
            address_line_2: null,
            zip_code: null,
            place: null,
            country: 'CH'
          },
          extension_fields: {
            email: '',
            phone: '',
            birth_date: null,
            die_date: null,
            mediate_from: '',
            family: '',
            notes: '',
            cooperation_start_date: null,
            cooperation_end_date: null,
          },
        },
        location: {
          latitude: null,
          longitude: null,
          radius: 50,
        },
        project_id: '',
        contact_user: {
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
        }
      },
      isImportProtected: false,
      itemOriginal: null,
      currentCustomerLocation: null,
      searchUsers: '',
      users: '',
      is_time_management: 0
    }
  },
  mounted() {
    this.itemOriginal = JSON.parse(JSON.stringify(this.item))
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    isCompany() {
      return this.item.address_type === 'company'
    },
    isPrivate() {
      return this.item.address_type === 'private'
    },
  },
  watch: {
    searchUsers: function (val) {
      this.getSearchResultUsers(encodeURIComponent(val))
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customers.detail.' + key)
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({name: 'Customers'})
    },
    saveItem() {
      const data = {
        ...this.item
      }
      return HTTP.post('customers', data)
    },
    onSaved(id) {
      this.$router.push({name: 'CustomersEdit', params: { id: id }})
    },
    setCustomerLocation(data) {
      this.item.location.latitude = data.location.lat
      this.item.location.longitude = data.location.lng
      this.item.location.radius = data.radius
    },
    async saveCustomerLocation(data) {
      //
    },
    userSelected(id, user) {
      if (id) {
        this.item.assigned.users.push(user)
        this.checkDuplicates('users')
        this.$refs.cfe.$refs.autocomplete.reset()
      }
    },
    checkDuplicates(type) {
      this.item.assigned[type] = _.uniqBy(this.item.assigned[type], 'id')
    },
    removeItem(type, index) {
      this.item.assigned[type].splice(index, 1)
    }
  }
}
</script>
