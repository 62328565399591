import { acceptHMRUpdate, defineStore } from 'pinia'
import moment from 'moment'

const store = defineStore('TaskFilterStore', {
  state: () => {
    return {
      filter: {
        selectedUser: null,
        selectedCustomer: null,
        dateStart: null,
        dateEnd: moment().format('YYYY-MM-DD'),
        status: ['OPEN', 'DONE'],
      },
      appliedFilter: '',
      table: {
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: ['created_at'],
        sortDesc: [true]
      },
    }
  },
  getters: {
    hasChanges: (state) => {
      return JSON.stringify(state.filter) !== JSON.stringify(state.appliedFilter)
    }
  },
  actions: {
    filterApplied() {
      this.appliedFilter = { ...this.filter }
    }
  },
  persist: true,
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
}

export const useTaskFilterStore = store
