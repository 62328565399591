<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        v-if="dataLoaded"
        :title="t('title')"
        @cancel="cancel"
        :allow-delete="false"
        :object-type="$t('absence.data.object-type')"
        :object-name="item.user.display_name"
        :has-changes="hasChanges">
      <template v-slot:default>
        <v-card
            elevation="0"
        >
          <v-card-title>{{ t('timelog.group-title') }}</v-card-title>
          <v-card-text>

            <div>
              <div class="disabled-input">
                <strong><span class="pb-3">{{ t('timelog.employee') }}</span></strong>
                <span class="bg-field">{{ item.user.display_name }}</span>
              </div>

              <div>
                <timesheet-approval-edit-week-view
                    :start-date="item.date_from"
                    v-model="item.timelogs"
                />
              </div>
            </div>

          </v-card-text>
        </v-card>

        <v-card
            elevation="0"
            class="sp-mar-top-3">
          <v-card-title>{{ t('journal.group-title') }}</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col class="date-header mr-1 dayWidth">
                  <strong>{{ t('journal.day') }}</strong>
                </v-col>

                <v-col  class="date-header  mr-1">
                  <strong>{{ t('journal.customer') }}</strong>
                </v-col>

                <v-col class="date-header  mr-1">
                  <strong>{{ t('journal.project') }}
                  / 
                  <span>
                    <br/>{{ t('journal.cost_center') }}
                  </span>
                  </strong>
                </v-col>

                <v-col v-if="showFromTo" class="date-header mr-1 dateWidth">
                  <strong>{{ t('journal.from') }}</strong>
                </v-col>

                <v-col v-if="showFromTo" class="date-header mr-1 dateWidth">
                  <strong>{{ t('journal.to') }}</strong>
                </v-col>

                <v-col class="date-header timeWidth">
                  <strong>{{ t('journal.hours') }}</strong>
                </v-col>

                <v-col v-if="theme === 'har'" class="date-header mr-1 iconWidth">
                  {{ t('journal.holiday') }}
                </v-col>

                <v-col v-if="theme === 'har'" class="date-header iconWidth">
                  {{ t('journal.expenses') }}
                </v-col>
              </v-row>

              <v-row v-for="timelog in timelogsOrdered" class="mb-4">
                <v-col class="content-header bg-field mr-1 dayWidth">
                  {{ formatDateWithDay(timelog.date) }}
                </v-col>

                <v-col class="ellipsis-wrapper bg-field mr-1">
                  <span v-if="timelog.customer" :title="timelog.customer.name" class="ellipsis ellipsis-title">
                    {{ timelog.customer.name }}
                  </span>
                </v-col>

                <v-col class="ellipsis-wrapper bg-field  mr-1">
                  <span v-if="timelog.project" :title="timelog.project.name" class="ellipsis ellipsis-title">
                    {{ timelog.project.name }}
                  </span><br/>
                  <span v-if="theme === 'har' && timelog.project && !timelog.is_time_management" :title="timelog.project.cost_center" class="ellipsis preview-text">
                    {{ timelog.project.cost_center }}
                  </span>
                </v-col>

                <v-col v-if="showFromTo" class="dateWidth bg-field mr-1">
                  {{ calcTimeFrom(timelog) }}
                </v-col>

                <v-col v-if="showFromTo" class="dateWidth bg-field mr-1">
                  {{ calcTimeTo(timelog) }}
                </v-col>

                <v-col  class="timeWidth bg-field mr-1">
                  {{ (timelog.duration_minutes / 60).toFixed(2) }}
                </v-col>

                <v-col v-if="theme === 'har'" class=" bg-field mr-1 iconWidth">
                  <v-icon v-if="timelog.is_holiday == true" >mdi-party-popper</v-icon>
                </v-col>

                <v-col v-if="theme === 'har'" class=" bg-field iconWidth">
                  <v-icon v-if="timelog.create_expense == true">mdi-hand-coin</v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

        <v-card
          elevation="0"
          class="sp-mar-top-3"
        >
          <v-card-title>{{ t('comment.group-title') }}</v-card-title>
          <v-card-text>

            <v-textarea
              v-if="!hasApprovalStatus"
              ref="commentTextArea"
              solo
              name="comment"
              v-model="approvalComment"
              :label="t('comment.hint')"
              :rules="rules.commentRules"
            ></v-textarea>

            <span v-if="hasApprovalStatus">
              {{ item.approval_comment }}<br/>
              <small>{{ item.approved_by.display_name }}</small>
            </span>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('history.group-title') }}</v-card-title>
          <v-card-text>
            <div class="disabled-input">
              <label>{{ t('history.created-at') }}</label>
              <span>{{ formatDateTime(item.created_at) }}</span>
            </div>

            <div class="disabled-input">
              <label>{{ t('history.status') }}</label>
              <span>{{ $t('timesheet-approval.data.status.' + item.status) }}</span>
            </div>

            <div class="disabled-input" v-if="hasApprovalStatus">
              <label v-if="item.status === 'approved'">{{ t('history.approved-at') }}</label>
              <label v-if="item.status === 'rejected'">{{ t('history.rejected-at') }}</label>
              <span>{{ formatDateTime(item.approval_date) }}</span>
            </div>

            <div class="disabled-input" v-if="hasApprovalStatus">
              <label v-if="item.status === 'approved'">{{ t('history.approved-by') }}</label>
              <label v-if="item.status === 'rejected'">{{ t('history.rejected-by') }}</label>
              <span>{{ item.approved_by.display_name }}</span>
            </div>
          </v-card-text>
        </v-card>
      </template>

      <template v-slot:action-buttons>
        <v-btn
          elevation="0"
          class="icon-left"
          @click="reject()"
          v-if="canEdit"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('reject') }}
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          class="icon-left"
          @click="approve()"
          v-if="canEdit"
        >
          <v-icon>mdi-check</v-icon>
          {{ t('approve') }}
        </v-btn>
      </template>
    </BasicEditContainer>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import loading from '@/components/layouts/Loading'
import toolbar from '@/components/layouts/Navigation'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import CancelButtonDialog from '@/components/layouts/CancelButtonDialog'
import TimesheetApprovalEditWeekView from '@/views/TimesheetApprovalEditWeekView'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import moment from 'moment/moment'
import store from 'store'

export default {
  components: {
    BasicEditContainer,
    TimesheetApprovalEditWeekView,
    CancelButtonDialog,
    loading,
    toolbar,
    DatePicker
  },
  data() {
    return {
      itemId: null,
      item: null,
      dataLoaded: false,
      approvalComment: '',
      imageData: null,
      rules: {
        commentRules: [
          v => !!v || this.t('comment.error'),
        ],
      },
      record_mode: '',
    }
  },
  async created() {
    this.itemId = this.$route.params.id
    await this.loadConfiguration()
    await this.loadData()
  },
  computed: {
    theme() {
      return store.get('theme')
    },
    canEdit() {
      return this.item && this.item.status === 'submitted'
    },
    hasApprovalStatus() {
      return this.item && (this.item.status === 'approved' || this.item.status === 'rejected')
    },
    hasChanges() {
      return this.canEdit && this.approvalComment !== ''
    },
    timelogsOrdered() {
      return this.item.timelogs.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date)
      })
    },
    showFromTo() {
      return this.record_mode !== 'hours'
    }
  },
  methods: {
    t: function (key) {
      return this.$t('timesheet-approval.detail.' + key)
    },
    async loadConfiguration() {
      let response = await HTTP.get('timelog/settings')
      this.record_mode = response.data.record_mode
    },
    calcTimeFrom(item) {
      return moment(item.date).format("HH:mm")
    },
    calcTimeTo(item) {
      return moment(item.date).add(item.duration_minutes, 'minutes').format("HH:mm")
    },
    async loadData() {
      let response = await HTTP.get('timelog/timesheets/' + this.itemId)
      this.item = response.data.data;
      this.dataLoaded = true;
    },
    async approve() {
      await this.doAction('approve');
      this.$root.infoNotification.showMessage(this.t('approved-confirm-message'));
    },
    async reject() {
      if (!this.approvalComment) {
        this.$refs.editor.validate()
        this.$refs.commentTextArea.focus()
        return;
      }
      await this.doAction('reject');
      this.$root.infoNotification.showMessage(this.t('reject-confirm-message'));
    },
    async doAction(action) {
      const json = {
        timesheet_id: this.itemId,
        comment: this.approvalComment
      }
      await HTTP.post('timelog/' + action + '-timesheet', json)
      this.gotoIndex()
    },
    cancel() {
      this.gotoIndex()
    },
    gotoIndex() {
      this.$router.push({name: 'TimesheetApproval'})
    },
  },
}
</script>

<style lang="scss" scoped>
.timeWidth {
  max-width: 87px;
}
.dateWidth {
  max-width: 57px;
  text-align: center;
}
.iconWidth {
  max-width: 85px;
  text-align: center;

}
.dayWidth {
  max-width: 120px;
  text-align: center;

}
.ellipsis-wrapper {
  position: relative;
  min-width: 115px;
  max-width:   calc(100% - 100px);
  .ellipsis {
    padding-right: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

    &.ellipsis-title {
      top: 20px;
      display: inline-block;
    }

    &.preview-text {
      top: 42px;
      display: inline-block;
    }
  }
}

.full-width {
  width: 100%;
}

.md-avatar.rectangle {
  margin: 0;
  border-radius: 0;
}

.md-theme-default.md-input-disabled {
  color: rgba(0, 0, 0, .38);
}

.mx-datepicker {
  margin-top: 0;
  margin-bottom: 0;
  max-width: 100%;
}

.disabled-input {
  margin-bottom: 24px;
  font-size: 16px;

  label {
    line-height: 24px;
    display: block;
    color: rgba(0, 0, 0, 0.54);
  }

  span {
    display: block;

    &.small {
      font-size: 14px;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid rgba(227, 227, 227, 0.7);
  margin: 16px 0;
}

.time-label {
  line-height: 24px;
  align-self: center;
}

.time-string {
  margin-left: 16px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  background: #f7f7f7;
  padding: 16px;
  border: 1px solid rgba(227, 227, 227, 0.7);
  border-radius: 4px;
}

.sp-flex-1 {
  max-width: 100%;
}

.hours {
  text-align: right;
}

.bg-field {
  padding: 8px;
  background-color: #F5F5F5;
}
</style>

