var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('toolbar',{attrs:{"dataLoaded":true}},[_vm._v(_vm._s(_vm.t('title') + _vm.customerName))]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"data-table-toolbar"},[_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","outlined":"","plain":"","to":{ name: 'CustomersEdit', params: { id: this.customerId } }}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s(_vm.t('back'))+" ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"icon-left",attrs:{"elevation":"0","color":"primary","data-cy":"createButton","to":{ name: 'CustomerProjectCreate', params: { id: this.customerId } },"disabled":_vm.isImportProtected,"title":_vm.getImportProtectedMessage()}},[_c('v-icon',[_vm._v(_vm._s(_vm.isImportProtected ? 'mdi-lock-outline' : 'mdi-plus'))]),_vm._v(" "+_vm._s(_vm.t('create'))+" ")],1)],1),(!_vm.loading && _vm.items.length === 0)?_c('v-row',[_c('div',{staticClass:"empty-datatable"},[_c('v-icon',[_vm._v("mdi-alert-circle-outline")]),_vm._v(" "+_vm._s(_vm.t('no-data'))+" ")],1)]):_c('v-row',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ellipsis"},[_vm._v(_vm._s(item.name))])]}},{key:"item.cost-center",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cost_center)+" ")]}},{key:"item.project-users",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.project_users.length)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? _vm.$t('customer-projects.data.status.active') : _vm.$t('customer-projects.data.status.inactive'))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'CustomerProjectEdit', params: { id: item.id  } },"data-cy":"editButton"}},[_c('v-icon',{staticClass:"edit-row-button",attrs:{"small":""}},[_vm._v(_vm._s(_vm.isImportProtected ? 'mdi-eye-outline' : 'edit'))])],1)]}}],null,true)})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }