<template>
  <div>
    <div>
      <toolbar :dataLoaded="true">{{ t('title') + customerName }}</toolbar>

      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            outlined
            plain
            :to="{ name: 'CustomersEdit', params: { id: this.customerId } }"
          >
            <v-icon>mdi-arrow-left</v-icon>
            {{ t('back') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left"
            @click="showAddUserDialog()"
            :disabled="isImportProtected"
            :title="getImportProtectedMessage()"
          >
            <v-icon>{{ isImportProtected ? 'mdi-lock-outline' : 'mdi-plus' }}</v-icon>
            {{ t('add') }}
          </v-btn>
        </v-row>

        <v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:[`header.is_customer_admin`]="{ header }">
              <v-icon>mdi-account-supervisor</v-icon>
            </template>  
          
            <template v-slot:[`item.picture`]="{ item }">
              <span v-if="!item.user.number.startsWith('CU')" class="user-imported" title="importiert"><md-icon>refresh-auto</md-icon></span>
              <span v-if="item.user.profile_image !== ''" class="user-profile-image">
                <img
                  class="user-profile-image"
                  v-if="item.user.profile_image"
                  :src="baseUrl + '/api/users/image/' + item.user.id + '/' + item.user.profile_image" />
              </span>
              <span v-else class="user-profile-image user-placeholder"><md-icon>person</md-icon></span>
            </template>  
          
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis">{{ item.user.display_name }}</span>
            </template>

            <template v-slot:[`item.number`]="{ item }">
              {{ item.user.number }}
            </template>
  
            <template v-slot:[`item.username`]="{ item }">
              {{ item.user.username }}
            </template>
  
            <template v-slot:[`item.role`]="{ item }">
              {{ $t('users.data.roles.' + item.user.role) }}
            </template>

            <template v-slot:[`item.employees`]="{ item }">
              {{ item.employees.length }}
            </template>

            <template v-slot:[`item.is_customer_admin`]="{ item }">
              <v-checkbox
                v-if="!changingCustomerAdmin"
                v-model="item.is_customer_admin"
                hide-details
                class="shrink mr-0 mt-0"
                :ripple="false"
                :disabled="!isCustomerAdmin"
                @change="customerAdminChanged($event, item.user.id)"
              ></v-checkbox>
              <v-progress-circular
                v-else
                :width="2"
                :size="24"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </template>

            <template v-slot:[`item.delete`]="{ item }">
              <v-icon @click="showRemoveUserDialog(item.user_id)" class="delete-handle">mdi-trash-can-outline</v-icon>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ name: 'CustomersContactsEdit', params: { id: item.customer_id, userid: item.user_id  } }" class="edit-row-table">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  {{ isImportProtected ? 'mdi-eye-outline' : 'edit' }}
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-row>

        <v-row v-if="customerProjectUsers.length > 0">
          <h3 class="project-users-title data-table-toolbar">
            {{ t('assigned_by_customer_project') }}
            <v-btn
              elevation="0"
              color="primary"
              class="icon-right float-right v-btn--active"
              outlined
              plain
              :to="{ name: 'CustomersProjectsIndex', params: { id: this.customerId } }"
            > 
              {{ t('to-project-management') }}
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </h3>
          <v-data-table
            :headers="headers2"
            :items="customerProjectUsers"
            class="elevation-0"
            hide-default-footer
          >
            <template v-slot:[`item.project-name`]="{ item }">
              {{ item.projectName }}
            </template>

            <template v-slot:[`item.project-users`]="{ item }">
              <div v-for="(pu, index) in item.projectUsers" :key="index" class="customer-project-user">
                <div class="customer-project-user-name">{{ pu.user.display_name }}</div>
                <div class="customer-project-user-meta">
                  <span>{{ $t('users.data.roles.' + pu.user.role) }}</span>
                  <span>{{ pu.user.number }}</span>
                  <span>{{ pu.user.username }}</span> 
                </div>
              </div>
            </template>
          </v-data-table>
        </v-row>

      </v-container>
    </div>

    <v-dialog
      v-model="addUserDialog"
      content-class="add-customer-user-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('dialog.title') }}
        </v-card-title>
        <v-card-text class="px-4">
          <div v-if="loadingUsers">
            <v-progress-circular
              :width="2"
              :size="24"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-text-field
              elevation="0"
              :label="t('dialog.user-search')"
              v-model="userSearchValue"
              append-icon="mdi-magnify"
              filled
              hide-details="auto"
            ></v-text-field>
            <div class="dialog-user-entry dialog-user-select-all">
              {{ t('dialog.select-all') }}
              <v-checkbox
                v-model="selectAll"
                hide-details
                class="shrink mr-0 mt-0"
                :ripple="false"
                @change="checkAll($event)"
              ></v-checkbox>
            </div>
            <div class="filtered-user-wrapper">
              <div v-for="(fu, index) in filteredUsers"
                :key="index"
                class="dialog-user-entry">
                <span>{{ fu.name + ' ' + fu.firstname }}</span>
                <v-checkbox
                  v-model="fu.selected"
                  hide-details
                  :ripple="false"
                  class="shrink mr-0 mt-0"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hideAddUserDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            :loading="isAddingUsers"
            @click="addUsers"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="removeUserDialog"
      content-class="remove-customer-user-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('dialog.remove.title') }}
        </v-card-title>
        <v-card-text class="px-4">
          {{ t('dialog.remove.text') }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hideRemoveUserDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            :loading="isRemovingUsers"
            @click="removeUser()"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('dialog.do') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import toolbar from '@/components/layouts/Navigation'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'

export default {
  name: "CustomersContactsIndex",
  components: {
    AppFilterApplyButton,
    AppFilterSearchField,
    toolbar
  },
  data() {
    return {
      customerId: null,
      customer: null,
      loading: false,
      loadingUsers: false,
      items: [],
      existingUsers: [],
      users: [],
      customerProjectUsers: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL ?? '',
      user: null,
      userSearchValue: '',
      selectAll: false,
      isImportProtected: false,
      isCustomerAdmin: false,
      changingCustomerAdmin: false,
      addUserDialog: false,
      removeUserDialog: false,
      removeUserId: null,
      isAddingUsers: false,
      isRemovingUsers: false
    }
  },
  created() {
    this.customerId = this.$route.params.id
    this.user = store.get('user')
    this.customerUsers()
  },
  computed: {
    customerName() {
      if (!this.loading && this.customer != null) {
        if (this.customer.name == null) {
          if (this.customer.firstname != null && this.customer.lastname != null) {
            return ' : ' + this.customer.firstname + ' ' + this.customer.lastname
          }
        } else {
          return ' : ' + this.customer.name
        }
      }
      return ''
    },
    roles() {
      return [
        { 'code': "admin", "name": this.$t('users.data.roles.admin') },
        { 'code': "coordinator", "name": this.$t('users.data.roles.coordinator') },
        { 'code': "employee", "name": this.$t('users.data.roles.employee') },
        { 'code': "customer", "name": this.$t('users.data.roles.customer') },
        { 'code': "none", "name": this.$t('users.data.roles.none') },
      ];
    },
    headers() {
      let headers = [
        {
          text: this.$t('users.list.table.profile-picture'),
          align: 'start',
          sortable: false,
          value: 'picture'
        },
        {
          text: this.$t('users.list.table.name'),
          sortable: false,
          value: 'name',
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.username'),
          value: 'username',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.role'),
          value: 'role',
          sortable: false,
          cellClass: 'text-middle',
          width: '75'
        },
        {
          text: this.$t('users.list.table.employees'),
          align: 'center',
          value: 'employees',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: '',
          value: 'is_customer_admin',
          sortable: false,
          cellClass: 'text-middle',
          width: '75'
        }
      ]

      if (!this.isImportProtected && this.isCustomerAdmin) {
        headers.push(
          {
            text: '',
            value: 'delete',
            sortable: false,
            cellClass: 'text-middle',
            width: '75'
          }
        )
      }

      headers.push(
        {
          text: '',
          value: 'edit',
          sortable: false,
          cellClass: 'text-middle',
          width: '75'
        }
      )

      return headers
    },
    headers2() {
      let headers2 = [
        {
          text: 'Projektname',
          value: 'project-name',
          sortable: false,
        },
        {
          text: 'Zugewiesene Mitarbeiter',
          value: 'project-users',
          sortable: false,
        }
      ]

      return headers2
    },
    filteredUsers() {
      if (this.userSearchValue !== '') {
        return this.users.filter((user) =>
          user.display_name.toLowerCase().includes(this.userSearchValue.toLowerCase())
        )
      } else {
        return this.users
      }
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customer-users.list.' + key)
    },
    customerUsers() {
      this.loading = true
      HTTP.get('customers/' + this.customerId + '/users')
        .then(function (response) {
          this.items = response.data.data
          this.customer = response.data.customer
          this.existingUsers = this.items.map((eu) => eu.user_id)
          if (this.user.role === 'admin') {
            this.isCustomerAdmin = true
          } else {
            let user = this.items.filter(u => u.id === this.user.id)
            if (user.length === 1) {
              this.isCustomerAdmin = !!user.is_customer_admin
            }
          }

          if (response.data.customer_project_users) {
            response.data.customer_project_users.forEach((cpu) => {
              if (cpu.project_users.length > 0) {
                this.customerProjectUsers.push({
                  projectName: cpu.name,
                  projectUsers: cpu.project_users
                })
              }
            })
          }
        }.bind(this)).finally(() => {
          this.loading = false
        })
    },
    getUsers() {
      this.loadingUsers = true

      HTTP.get('users?onlyActive=true').then(function (response) {
        this.users = response.data.filter(u => !this.existingUsers.includes(u.id))
        this.users.forEach((user) => {
            this.$set(user, 'selected', false)
        })
        this.loadingUsers = false
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.loadingUsers = false
      }.bind(this))
    },
    addUsers() {
      if (this.isAddingUsers) {
        return
      }
      this.isAddingUsers = true

      const json = JSON.stringify({
        users: this.users.filter((u) => u.selected)
      })

      HTTP.post('customers/' + this.customerId  + '/users/add', { 'data': json }).then(function () {
        this.hideAddUserDialog()
        this.isAddingUsers = false
        this.reload()
      }.bind(this)).catch(function (error) {
        if (error.response.status && error.response.status === 304) {
          this.$root.infoNotification.showMessage(this.t('http-error.304'))
        } else {
          this.$root.infoNotification.showMessage(this.t('http-error.unknown') + error.response.statusText)
        }
        this.isAddingUsers = false
      }.bind(this))
    },
    removeUser() {
      if (this.isRemovingUsers) {
        return
      }
      this.isRemovingUsers = true
      HTTP.post('customers/' + this.customerId + '/user/' + this.removeUserId + '/remove')
        .then(function () {
          this.hideRemoveUserDialog()
          this.reload()
        }.bind(this)).finally(() => {
          this.isRemovingUsers = false
        })
    },
    customerAdminChanged(ev, userid) {
      this.changingCustomerAdmin = true
      HTTP.post('customers/' + this.customerId + '/user/' + userid)
        .then(function () {
          this.reload()
        }.bind(this)).finally(() => {
          this.changingCustomerAdmin = false
        })
    },
    checkAll(checkAll) {
      this.filteredUsers.forEach((fu) => {
         fu.selected = checkAll
      })
    },
    showAddUserDialog() {
      this.getUsers()
      this.addUserDialog = true
    },
    hideAddUserDialog() {
      this.addUserDialog = false
    },
    showRemoveUserDialog(userid) {
      this.removeUserId = userid
      this.removeUserDialog = true
    },
    hideRemoveUserDialog() {
      this.removeUserId = null
      this.removeUserDialog = false
    },
    reload() {
      this.items = []
      this.customerProjectUsers = []
      this.customerUsers()
    },
    getImportProtectedMessage() {
      return this.isImportProtected ? this.$t('customer-projects.import-protected') : ''
    }
  }
}
</script>

<style scoped lang="scss">
.user-profile-image {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.30);
}

.user-placeholder i {
  display: block;
  font-size: 30px;
  line-height: 36px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: rgba(0, 0, 0, 0.30);
}

.user-imported {
  position: absolute;
  z-index: 1;
  top: 5px;
  left: 40px;
  border-radius: 100%;
  border: 1px solid #999;
  background: #FFF;
  overflow: hidden;

  i {
    color: #999;
  }
}

.ellipsis {
  display: block;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.project-users-title {
  width: 100%;
  line-height: 36px;
}

.customer-project-user {
  margin-bottom: 4px;
  border-bottom: 1px solid #F5F5F5;

  &:last-child {
    margin-bottom: 0;
    border: 0;
  }

  .customer-project-user-meta {
    display: flex;
    gap: 16px;
    font-size: 14px;
    position: relative;
    top: -4px;
    opacity: 0.75;
  }
}

.add-customer-user-dialog {

  .v-progress-circular {
    display: block;
    margin: 0 auto;
    min-height: 100px;
  }

  .dialog-user-entry {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    line-height: 28px;
  
    &:not(.dialog-user-select-all):hover {
      background-color: #F5F5F5;
    }
  
    &.dialog-user-select-all {
      padding: 10px;
      border-bottom: 1px solid #E0E0E0;
    }
  
    span:first-child {
      max-width: calc(100% - 40px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .v-input {
      position: relative;
      top: -2px;
      right: -13px;
    }
  }

  .filtered-user-wrapper {
    max-height: calc(100vh - 460px);
    margin: 10px 0;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
