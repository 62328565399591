<template>
  <div>
    <BasicEditContainer
        ref="editor"
        :data-loaded="dataLoaded"
        :title="t('title')"
        @cancel="cancel"
        :save-action="saveItem"
        @saved="onSaved"
        object-type="CustomerProject"
        :object-name="dataLoaded ? item.name : ''"
        :allow-delete="false"
        :has-changes="hasChanges"
      >
        <template v-slot:default>

          <v-card elevation="0">
            <v-card-title>{{ t('details.group-title') }}</v-card-title>
            <v-card-text>
              <v-text-field
                :label="t('details.customer')"
                v-model="customerName"
                filled
                :disabled="isImportProtected"
              ></v-text-field>

              <v-text-field
                :label="t('details.name') + '*'"
                counter="120"
                v-model="item.name"
                data-cy="name"
                filled
                :disabled="isImportProtected"
                :rules="[
                  () => !!item.name || $t('validation.required.name'),
                  () => (item.name && item.name.length <= 120) || generateLengthMessage(t('details.name'), 120)
                ]"
              ></v-text-field>

              <v-text-field
                :label="t('details.cost-center')"
                counter="120"
                v-model="item.cost_center"
                data-cy="cost_center"
                filled
                :disabled="isImportProtected"
              ></v-text-field>

              <v-text-field
                :label="t('details.purchase-document')"
                counter="120"
                v-model="item.purchase_document"
                data-cy="purchase_document"
                filled
                :disabled="isImportProtected"
              ></v-text-field>

              <v-checkbox
                v-model="item.time_type"
                class="mt-0"
                :label="t('details.time_type')"
                hide-details
                :disabled="isImportProtected"
              >
              </v-checkbox>
            </v-card-text>
          </v-card>

          <v-card elevation="0" class="sp-mar-top-3">
            <v-card-title>{{ t('employees.card-title') }}</v-card-title>
            <v-card-text v-if="dataLoaded">
              
              <div class="d-flex justify-end sp-mar-bot-2">
                <v-btn
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  :disabled="isImportProtected"
                  :title="getImportProtectedMessage()"
                  @click="showAddUserDialog()"
                >
                  <v-icon>{{ isImportProtected ? 'mdi-lock-outline' : 'mdi-plus' }}</v-icon>
                  {{ t('employees.add') }}
                </v-btn>
              </div>
              
              <v-data-table
                :headers="headers"
                :items="item.project_users"
                class="elevation-0 mb-0"
                hide-default-footer
              >            
                <template v-slot:[`item.name`]="{ item }">
                  <span class="ellipsis">{{ item.user ? item.user.display_name : item.display_name }}</span>
                </template>
  
                <template v-slot:[`item.number`]="{ item }">
                  {{ item.user ? item.user.number : item.number }}
                </template>
      
                <template v-slot:[`item.username`]="{ item }">
                  {{ item.user ? item.user.username : item.username }}
                </template>
      
                <template v-slot:[`item.role`]="{ item }">
                  {{ getUserRoleName(item) }}
                </template>
  
                <template v-slot:[`item.delete`]="{ item, index }">
                  <v-icon @click="removeUser(index)" class="delete-handle">mdi-trash-can-outline</v-icon>
                </template>
              </v-data-table>
  
            </v-card-text>
          </v-card>
        </template>

        <template v-slot:meta>
          <v-card elevation="0">
            <v-card-title>{{ t('status.group-title') }}</v-card-title>
            <v-card-text>

              <v-switch
                v-model="item.is_active"
                inset
                :label="t(item.is_active ? 'status.active': 'status.inactive')"
                :disabled="isImportProtected"
              ></v-switch>

            </v-card-text>
          </v-card>
        </template>
    </BasicEditContainer>

    <v-dialog
      v-model="addUserDialog"
      content-class="add-customer-user-dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ t('details.dialog.title') }}
        </v-card-title>
        <v-card-text class="px-4">
          <div v-if="loadingUsers">
            <v-progress-circular
              :width="2"
              :size="24"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            <v-text-field
              elevation="0"
              :label="t('details.dialog.user-search')"
              v-model="userSearchValue"
              append-icon="mdi-magnify"
              filled
              hide-details="auto"
            ></v-text-field>
            <div class="dialog-user-entry dialog-user-select-all">
              {{ t('details.dialog.select-all') }}
              <v-checkbox
                v-model="selectAll"
                hide-details
                class="shrink mr-0 mt-0"
                :ripple="false"
                @change="checkAll($event)"
              ></v-checkbox>
            </div>
            <div class="filtered-user-wrapper">
              <div v-for="(fu, index) in filteredUsers"
                :key="index"
                class="dialog-user-entry">
                <span>{{ fu.name + ' ' + fu.firstname }}</span>
                <v-checkbox
                  v-model="fu.selected"
                  hide-details
                  :ripple="false"
                  class="shrink mr-0 mt-0"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            elevation="0"
            color="primary"
            text
            class="icon-left px-4"
            @click="hideAddUserDialog"
          >
            <v-icon>mdi-close</v-icon>
            {{ t('details.dialog.cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            color="primary"
            class="icon-left px-4"
            :loading="isAddingUsers"
            @click="addUsers"
          >
            <v-icon>mdi-check</v-icon>
            {{ t('details.dialog.add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { HTTP } from '@/auth'
import BasicEditContainer from '@/components/inputs/BasicEditContainer'
import { generateLengthMessage } from '@/mixins/validationMessages'

export default {
  name: "CustomerProjectEdit",
  components: {
    BasicEditContainer,
  },
  data() {
    return {
      itemId: null,
      dataLoaded: false,
      item: {
        name: '',
        cost_center: '',
        is_active: true,
        purchase_document: '',
        customer_id: null,
        customer: null,
        time_type: false,
        project_users: null
      },
      itemOriginal: null,
      isImportProtected: false,
      addUserDialog: false,
      loadingUsers: false,
      users: [],
      existingUsers: [],
      userSearchValue: '',
      isAddingUsers: false,
      isRemovingUsers: false,
      selectAll: false
    }
  },
  created() {
    this.itemId = this.$route.params.id
    this.loadData()
  },
  computed: {
    hasChanges() {
      if (!this.itemOriginal) {
        return false
      }
      return JSON.stringify(this.item) !== JSON.stringify(this.itemOriginal)
    },
    customerName() {
      if (this.dataLoaded) {
        if (this.item.customer.name == null) {
          if (this.item.customer.firstname != null && this.item.customer.lastname != null) {
            return this.item.customer.firstname + ' ' + this.item.customer.lastname
          }
        } else {
          return this.item.customer.name
        }
      }
      return ''
    },
    headers() {
      let headers = [
        {
          text: this.$t('users.list.table.name'),
          sortable: false,
          value: 'name',
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.number'),
          value: 'number',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.username'),
          value: 'username',
          sortable: false,
          cellClass: 'text-middle'
        },
        {
          text: this.$t('users.list.table.role'),
          value: 'role',
          sortable: false,
          cellClass: 'text-middle',
          width: '75'
        }
      ]

      if (!this.isImportProtected) {
        headers.push(
          {
            text: '',
            value: 'delete',
            sortable: false,
            cellClass: 'text-middle',
            width: '75'
          }
        )
      }

      return headers
    },
    filteredUsers() {
      if (this.userSearchValue !== '') {
        return this.users.filter((user) =>
          user.display_name.toLowerCase().includes(this.userSearchValue.toLowerCase())
        )
      } else {
        return this.users
      }
    }
  },
  methods: {
    generateLengthMessage,
    t: function (key) {
      return this.$t('customer-projects.detail.' + key)
    },
    loadData() {
      HTTP.get('customer-projects/' + this.itemId)
          .then(function (response) {
            this.item = response.data
            this.itemOriginal = JSON.parse(JSON.stringify(this.item))
            this.setExistingUsers()
          }.bind(this)).finally(function () {
        this.dataLoaded = true
      }.bind(this))
    },
    getUsers() {
      this.loadingUsers = true
      HTTP.get('users?onlyActive=true').then(function (response) {
        this.users = response.data.filter(u => !this.existingUsers.includes(u.id))
        this.users.forEach((user) => {
            this.$set(user, 'selected', false)
        })
        this.loadingUsers = false
      }.bind(this)).catch(function (error) {
        console.log(error)
        this.loadingUsers = false
      }.bind(this))
    },
    addUsers() {
      let usersToAdd = this.users.filter((u) => u.selected)
      this.item.project_users.push(...usersToAdd)
      this.setExistingUsers()
      this.hideAddUserDialog()
      this.selectAll = false
      this.userSearchValue = ''
    },
    removeUser(index) {
      this.item.project_users.splice(index, 1)
      this.setExistingUsers()
    },
    saveItem() {
      return HTTP.post('customer-projects/' + this.itemId, this.item)
    },
    onSaved() {
      this.close()
    },
    cancel() {
      this.close()
    },
    close() {
      this.$router.push({name: 'CustomersProjectsIndex', params: { id: this.item.customer_id }})
    },
    checkAll(checkAll) {
      this.filteredUsers.forEach((fu) => {
        fu.selected = checkAll
      })
    },
    setExistingUsers() {
      // todo: add customerUser
      // add project_users
      this.existingUsers = this.item.project_users.map((eu) => eu.user_id || eu.id)
    },
    getUserRoleName(item) {
      if (item.user) {
        return this.$t('users.data.roles.' + item.user.role)
      } else {
        return this.$t('users.data.roles.' + item.role)
      }
    },
    showAddUserDialog() {
      this.getUsers()
      this.addUserDialog = true
    },
    hideAddUserDialog() {
      this.addUserDialog = false
    },
    getImportProtectedMessage() {
      return this.isImportProtected ? this.$t('customer-projects.import-protected') : ''
    }
  }
}
</script>

<style scoped lang="scss">
.add-customer-user-dialog {

  .v-progress-circular {
    display: block;
    margin: 0 auto;
    min-height: 100px;
  }

  .dialog-user-entry {
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    line-height: 28px;
  
    &:not(.dialog-user-select-all):hover {
      background-color: #F5F5F5;
    }
  
    &.dialog-user-select-all {
      padding: 10px;
      border-bottom: 1px solid #E0E0E0;
    }
  
    span:first-child {
      max-width: calc(100% - 40px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .v-input {
      position: relative;
      top: -2px;
      right: -13px;
    }
  }

  .filtered-user-wrapper {
    max-height: calc(100vh - 460px);
    margin: 10px 0;
    overflow: hidden;
    overflow-y: auto;
  }
}
</style>
