<template>
  <div class="has-hero-container">
    <div>

      <toolbar :dataLoaded="true">{{ $t('customers.list.title') }}</toolbar>

      <div class="sp-hero sp-bg-light-blue">
        <v-container fluid class="hero-filter" v-on:keyup.enter="applyFilter(); options.page = 1">

          <AppFilterApplyButton
            v-if="filterStore.hasChanges"
            @click="applyFilter();  options.page = 1">
          </AppFilterApplyButton>

          <v-slide-group show-arrows>

            <v-slide-item>

              <div>
                <div class="filter-item">
                  <app-filter-search-field
                    v-model="filterStore.filter.searchTerm"
                    :filterLabel="$t('components.app-filter-search-field.label.customer')"
                    clearable
                    append-icon="mdi-magnify"
                  />
                </div>
              </div>

            </v-slide-item>

            <v-slide-item>
              <div class="filter-item">
                <v-checkbox
                  v-model="filterStore.filter.withInactive"
                  :label="$t('customers.list.filter.inactive')"
                />
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-container>
      </div>
      
      <v-container fluid>
        <v-row class="data-table-toolbar">
          <v-spacer></v-spacer>
          <div v-show="dataLoaded && items.length > 0">
                <v-btn
                  outlined
                  color="primary"
                  elevation="0"
                  class="icon-left"
                  :disabled="!readyToDownload"
                  :loading="isDownloadingExport"
                  @click="exportExcel()"
                  data-cy="excelButton"
                >
                  <v-icon>mdi-download</v-icon>
                  {{ $t('customers.list.excel-export') }}
                </v-btn>
            </div>
          <div>
            <v-btn
              v-if="isAdminOrCoordinator()"
              elevation="0"
              color="primary"
              class="icon-left"
              :to="{ path: '/customers/create'}"
              data-cy="createButton"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('customers.list.create') }}
            </v-btn>
          </div>
        </v-row>

        <v-row>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            class="elevation-0"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :sort-by.sync="filterStore.table.sortBy"
            :sort-desc.sync="filterStore.table.sortDesc"
            :page.sync="filterStore.table.currentPage"
            :items-per-page.sync="filterStore.table.itemsPerPage"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-minus',
              nextIcon: 'mdi-plus',
              'items-per-page-options': [25, 50, 100, 200],
              pageText: '{0}-{1} von {2}',
              'items-per-page-text': t('table.items-per-page')
            }"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span class="ellipsis">{{ item.name }}</span>
            </template>

            <template v-slot:[`item.address_street`]="{ item }">
              <span class="ellipsis">{{ item.address.address_line_1 }}</span>
            </template>

            <template v-slot:[`item.address_city`]="{ item }">
              <span class="ellipsis">{{ item.address.zip_code }} {{ item.address.place }}</span>
            </template>

            <template v-slot:[`item.phone`]="{ item }">
              <span class="ellipsis">{{ item.phone }}</span>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <router-link :to="{ path: '/customers/' + item.id }" class="edit-row-table"  data-cy="editButton">
                <v-icon
                    small
                    class="edit-row-button"
                >
                  edit
                </v-icon>
              </router-link>
            </template>
          </v-data-table>

        </v-row>

      </v-container>

    </div>
  </div>
</template>

<script>
import { HTTP } from '@/auth'
import store from 'store'
import moment from 'moment'
import toolbar from '@/components/layouts/Navigation'
import ContentFilterCustomer from '@/components/filter-components/ContentFilterCustomer'
import AppFilterApplyButton from '@/components/vuetify/AppFilterApplyButton'
import AppFilterSearchField from '@/components/vuetify/AppFilterSearchField'
import { useCustomerFilterStore } from '@/stores/CustomerFilterStore'

export default {
  name: 'CustomersIndex',
  components: {
    AppFilterSearchField,
    AppFilterApplyButton,
    ContentFilterCustomer,
    toolbar,
  },
  setup() {
    const filterStore = useCustomerFilterStore()
    return { filterStore }
  },
  data() {
    return {
      loading: false,
      options: {},
      items: [],
      itemsTotal: 0,
      isDownloadingExport: false,
      dataLoaded: false,
      data: {
        id: null,
        assigned_users: [],
        active: true,
        deleted_at: null,
        selectedPlace: {
          lat: null,
          lng: null,
          radius: 50
        }
      },
      place: null
    }
  },
  created() {
    this.applyFilter()
    this.getLoginUser()
  },
  computed: {
    headers() {
      return [
        {
          text: this.t('table.customer'),
          value: 'name',
          align: 'start',
          sortable: true
        },
        {
          text: this.t('table.firstname'),
          value: 'firstname',
          align: 'start',
          sortable: true
        },
        {
          text: this.t('table.lastname'),
          value: 'lastname',
          align: 'start',
          sortable: true
        },
        {
          text: this.t('table.address_street'),
          value: 'address_street',
          align: 'start',
          sortable: false
        },
        {
          text: this.t('table.address_city'),
          value: 'address_city',
          align: 'start',
          sortable: false
        },
        {
          text: this.t('table.phone'),
          value: 'phone',
          align: 'start',
          sortable: false
        },
        {
          text: this.t('table.details'),
          value: 'edit',
          align: 'center',
          sortable: false
        }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.loadData()
      },
      deep: true,
    }
  },
  methods: {
    t: function (key) {
      return this.$t('customers.list.' + key)
    },
    reload() {
      this.$nextTick(function () {
        this.items = []
        this.loadData()
      }.bind(this))
    },
    applyFilter() {
      this.filterStore.table.currentPage = 1
      this.filterStore.filterApplied()
      this.reload()
    },
    loadData() {
      this.loading = true
  
      let q = '?';
      if (this.filterStore.filter.searchTerm) {
        q += '&q=' + this.filterStore.filter.searchTerm
      }

      if (this.filterStore.filter.withInactive) {
        q += '&withInactive'
      }

      HTTP.get('customers' + q, {params: this.options})
          .then(function (response) {
            this.items = response.data.data
            this.itemsTotal = response.data.total
            this.dataLoaded = true
            this.loading = false
          }.bind(this))
    },
    getLoginUser() {
      this.loginUser = store.get('user')
    },
    isAdminOrCoordinator() {
      return this.loginUser && (this.loginUser.role === 'admin' || this.loginUser.role === 'coordinator')
    },
    readyToDownload: function () {
        return this.dataLoaded && !this.isDownloadingExport
    },
    exportExcel() {
      this.isDownloadingExport = true

      let q = ''
      if (this.filterStore.filter.searchTerm) {
        q = '?q=' + this.filterStore.filter.searchTerm
      }

      HTTP.post('dataexport/excel/customers' + q, {params: this.options}, {responseType: 'arraybuffer'}).then(function (response) {
                let headers = response.headers
                let blob = new Blob([response.data], {type: headers['content-type']})
                let link = document.createElement('a')
                link.style = 'display: none'
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Kunden' + moment().format('DDMMYYYY-HHmm') + '.xlsx'
                document.body.appendChild(link)
                link.click()
                setTimeout(function () {
                    document.body.removeChild(link)
                    window.URL.revokeObjectURL(blob)
                }, 100)
                this.isDownloadingExport = false
            }.bind(this))

    }
  }
}
</script>

<style type="text/css">
.vue-map {
  height: 100%;
}

.md-list-item {
  cursor: default;
}

.map-dialog .md-dialog {
  width: 60%;
}

.md-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>
