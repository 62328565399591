<template>
  <div>

    <BasicNewContainer
        :title="t('create.title')"
        ref="editor"
        @cancel="cancel"
        :saveAction="saveItem"
        @saved="close"
        :has-changes="hasChanges">
      <template v-slot:default>
        <v-card elevation="0">
          <v-card-title>{{ t('details.group-title') }}</v-card-title>
          <v-card-text>
            <salutation-select
                v-model="data.salutation"
            ></salutation-select>
            <v-text-field
                filled
                :label="t('details.firstname') + '*'"
                counter="120"
                v-model="data.firstname"
                :rules="[
								() => !!data.firstname || $t('validation.required.firstname'),
								() => (data.firstname && data.firstname.length <= 120) || $t('validation.length.default')
							]"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.lastname') + '*'"
                counter="120"
                v-model="data.name"
                :rules="[
								() => !!data.name || $t('validation.required.lastname'),
								() => (data.name && data.name.length <= 120) || $t('validation.length.default')
							]"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.email')"
                v-model="data.email"
                :rules="emailRules"
            ></v-text-field>
            <v-text-field
                filled
                :label="t('details.phone')"
                v-model="data.phone_number"
            />
            <v-text-field
                filled
                :label="t('details.phone-mobile')"
                v-model="data.mobile_phone_number"
            />
            <v-text-field
                filled
                :label="t('details.phone-emergency')"
                v-model="data.emergency_phone_number"
            />
            <v-text-field
                filled
                :label="t('details.workplace')"
                v-model="data.workplace"
            />
            <v-text-field
                filled
                :label="t('details.profession')"
                v-model="data.profession"
            />
            <v-checkbox
                :label="t('details.phonebook-visible')"
                v-model="data.phonebook_hidden"
                v-if="moduleEnabled('phonebook')"
                :true-value="0"
                :false-value="1"
            />

            <v-text-field
                filled
                :label="t('details.bank')"
                v-model="data.bank"
            />

            <v-text-field
                filled
                :label="t('details.iban')"
                v-model="data.iban"
            />

            <app-filter-date-picker
                :filterLabel="t('details.birth_date')"
                v-model="data.birth_date"
                id="dateStart"
                :clearable="true"
            ></app-filter-date-picker>

            <v-textarea
                filled
                :label="t('details.notes')"
                v-model="data.notes"
            />

            <span>
              {{ t('details.cooperation') }}
            </span>
            <v-row class="mt-1">
              <v-col>
                <app-filter-date-picker
                    :filterLabel="t('details.begin_date')"
                    v-model="data.begin_date"
                    id="dateStart"
                    :clearable="true"
                ></app-filter-date-picker>
              </v-col>
              <v-col>
                <app-filter-date-picker
                    :filterLabel="t('details.end_date')"
                    v-model="data.end_date"
                    id="dateStart"
                    :clearable="true"
                ></app-filter-date-picker>
              </v-col>
            </v-row>

          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('user-roles.group-title') }}</v-card-title>
          <v-card-text>
            <v-radio-group v-model="data.role">
              <v-radio v-show="isAdmin()" :label="$t('users.data.roles.admin')" value="admin"/>
              <v-radio :label="$t('users.data.roles.coordinator')" value="coordinator"/>
              <v-radio :label="$t('users.data.roles.employee')" value="employee"/>
              <v-radio :label="$t('users.data.roles.customer')" value="customer"/>
              <v-radio :label="$t('users.data.roles.none')" value="none"/>
            </v-radio-group>
          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('avatar.group-title') }}</v-card-title>
          <v-card-text>
            <PictureUpload :deletion=true v-model="data.picture" aspectMode="quadratic"/>
          </v-card-text>
        </v-card>
        <v-card elevation="0" class="sp-mar-top-3">
          <v-card-title>{{ t('address.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
                filled
                :label="t('address.address_line_1')"
                v-model="data.address.address_line_1"
            />
            <v-text-field
                filled
                :label="t('address.address_line_2')"
                v-model="data.address.address_line_2"
            />
            <v-text-field
                filled
                :label="t('address.zip_code')"
                v-model="data.address.zip_code"
            />
            <v-text-field
                filled
                :label="t('address.place')"
                v-model="data.address.place"
            />
            <v-text-field
                filled
                :label="t('address.country')"
                v-model="data.address.country"
            />
          </v-card-text>
        </v-card>
      </template>
      <template v-slot:meta>
        <v-card elevation="0">
          <v-card-title>{{ t('login-data.group-title') }}</v-card-title>
          <v-card-text>
            <v-text-field
              filled
              :label="t('login-data.username') + '*'"
              counter="120"
              v-model="data.username"
              @keyup="lowercase"
              :spellcheck="false"
              :rules="[
								() => !!data.username || $t('validation.required.username'),
								() => (data.username && data.username.length <= 120) || $t('validation.length.default')
							]"
            />
            <v-text-field
                filled
                :label="t('login-data.password')"
                v-model="data.password"
                type="password"
            />
          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('admin.group-title') }}</v-card-title>
          <v-card-text>
            <content-filter-employee
                :label="t('admin.manager')"
                v-model="data.manager"/>
          </v-card-text>
        </v-card>
        <v-card elevation="0"
                class="sp-mar-top-3">
          <v-card-title>{{ t('access-rights.group-title') }}</v-card-title>
          <v-card-text>
            <div v-if="moduleEnabled('quality_report')">
              <div v-if="moduleEnabled('quality_report')" class="sp-pad-2">
                <div class="md-body-2">{{ t('access-rights.customers.title') }}</div>
                <span class="md-caption">{{ t('access-rights.customers.hint') }}</span>
                <div>
                  <md-input-container>
                    <label>{{ t('access-rights.customers.search') }}</label>
                    <md-input type="text" v-model="searchCustomers"></md-input>
                    <md-icon>search</md-icon>
                  </md-input-container>
                </div>

                <div class="search-items" v-for="(item, index) in customers" :key="item.id"
                     @click="selectItem('customers', item)" v-if="searchCustomers !== ''"> {{ item.name }}
                </div>
                <div v-if="data.assigned.customers !== 0" v-for="(item, index) in data.assigned.customers"
                     :key="item.id" class="search-items item-selected-inline search-multi">
                  <span>{{ item.name != null ? item.name : item.lastname + ', ' + item.firstname }}</span>
                  <div class="sp-float-right">
                    <md-icon class="sp-text-white" @click.native="removeItem('customers', index)">remove_circle
                    </md-icon>
                  </div>
                </div>
              </div>

              <md-divider v-if="moduleEnabled('quality_report')"></md-divider>

              <div v-if="moduleEnabled('quality_report')" class="sp-pad-2">
                <div class="md-body-2">{{ t('access-rights.checklists.title') }}</div>
                <span class="md-caption">{{ t('access-rights.checklists.hint') }}</span>
                <div>
                  <md-input-container>
                    <label>{{ t('access-rights.checklists.search') }}</label>
                    <md-input type="text" v-model="searchChecklists"></md-input>
                    <md-icon>search</md-icon>
                  </md-input-container>
                </div>
                <div class="search-items" v-for="(item, index) in checklists" :key="item.id"
                     @click="selectItem('checklists', item)" v-if="searchChecklists !== ''"> {{ item.name }}
                </div>
                <div v-if="data.assigned.checklists !== 0" v-for="(item, index) in data.assigned.checklists"
                     :key="item.id" class="search-items item-selected-inline search-multi">
                  <span>{{ item.name }}</span>
                  <div class="sp-float-right">
                    <md-icon class="sp-text-white" @click.native="removeItem('checklists', index)">remove_circle
                    </md-icon>
                  </div>
                </div>
              </div>

              <md-divider v-if="moduleEnabled('quality_report')"></md-divider>
            </div>
            <div class="sp-pad-2">
              <permission-group-selector
                  :model="data.assigned.permission_groups"
                  :required="true"
                  mode="dropdown"/>

            </div>
            <div class="sp-pad-2">
              <CustomerProjectSelector @item-selected="onCustomerProjectSelected"/>
              <div v-for="(item, index) in data.customer_projects_ids">
                <v-row>
                  <v-col>{{ customer_projects[item].customer.name }}</v-col>
                  <v-col>{{ customer_projects[item].name }}</v-col>
                  <v-col>
                    <v-btn @click="data.customer_projects_ids.splice(index, 1)">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </div>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </BasicNewContainer>
  </div>
</template>

<script>
import {HTTP} from './../auth'
import store from 'store'
import {hasModule} from '@/services/LicenseService'
import axios from 'axios'
import _ from 'lodash'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import PictureUpload from '@/components/inputs/PictureUpload'
import BasicNewContainer from '@/components/inputs/BasicNewContainer'
import CustomerProjectSelector from '@/components/CustomerProjectSelector'
import AppFilterDatePicker from '@/components/vuetify/AppFilterDatePicker'
import SalutationSelect from '@/components/SalutationSelect.vue'
import PermissionGroupSelector from '@/components/PermissionGroupSelector.vue'
import ContentFilterEmployee from "@/components/filter-components/ContentFilterEmployee.vue";

export default {
  name: 'UsersCreate',
  components: {
    ContentFilterEmployee,
    PermissionGroupSelector,
    CustomerProjectSelector,
    BasicNewContainer,
    PictureUpload,
    VueCropper,
    AppFilterDatePicker,
    SalutationSelect
  },
  data() {
    return {
      dataOriginal: null,
      data: {
        role: 'admin',
        assigned: {
          groups: [],
          customers: [],
          checklists: [],
          permission_groups: []
        },
        notifications: {
          groups: [],
          customers: [],
          checklists: []
        },
        picture: null,
        workplace: '',
        profession: '',
        phonebook_hidden: 0,
        address: {
          address_line_1: null,
          address_line_2: null,
          zip_code: null,
          place: null,
          country: null
        },
        salutation: '',
        bank: '',
        iban: '',
        birth_date: '',
        notes: '',
        begin_date: '',
        end_date: '',
        email: '',
        customer_projects_ids: []
      },
      manager: null,
      customer_projects: {},
      searchGroups: '',
      searchCustomers: '',
      searchChecklists: '',
      searchGroupsNotifications: '',
      searchCustomersNotifications: '',
      searchChecklistsNotifications: '',
      groups: null,
      permissionGroups: [],
      customers: null,
      checklists: null,
      groupsNotifications: null,
      customersNotifications: null,
      checklistsNotifications: null,
      error: null,
      errorMessage: 'Die Daten konnten nicht gespeichert werden, prüfen Sie Ihre Verbndung und versuchen Sie es nochmal.',
      cancelHTTP: null,
      errorCancel: null,
      loginUser: null
    }
  },
  props: {
    userData: null
  },
  created() {
    this.dataOriginal = JSON.parse(JSON.stringify(this.data))
    this.getPermissionGroups()
    this.loginUser = store.get('user')

    const queryParams = this.parseQueryParams(window.location.search);

    if (typeof queryParams.customer_id !== "undefined") {
      this.data.role = "employee";

      HTTP.get('client-admin-id').then(function (response) {
        this.data.assigned.permission_groups = [response.data];
      }.bind(this)).catch(function (error) {
        this.error = error
      }.bind(this))
    }
  },
  watch: {
    searchGroups: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val))
    },
    searchCustomers: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val))
    },
    searchChecklists: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val))
    },
    searchGroupsNotifications: function (val) {
      this.getSearchResultGroups(encodeURIComponent(val), true)
    },
    searchCustomersNotifications: function (val) {
      this.getSearchResultCustomers(encodeURIComponent(val), true)
    },
    searchChecklistsNotifications: function (val) {
      this.getSearchResultChecklists(encodeURIComponent(val), true)
    }
  },
  computed: {
    hasChanges() {
      return JSON.stringify(this.data) !== JSON.stringify(this.dataOriginal);
    },
    emailRules() {
      if (this.data.email == "") {
        return [];
      }
      return [
        v => /.+@.+/.test(v) || this.$t('validation.valid.email'),
      ]
    },
  },
  methods: {
    t: function (key) {
      return this.$t('users.detail.' + key);
    },
    lowercase() {
      this.data.username = this.data.username.toLowerCase()
    },
    moduleEnabled(string) {
      return hasModule(string)
    },
    onCustomerProjectSelected(id, data) {
      this.data.customer_projects_ids.push(id);
      this.customer_projects[id] = data;
    },
    getPermissionGroups() {
      HTTP.get('permission-groups').then(function (response) {
        this.permissionGroups = response.data.data
      }.bind(this)).catch(function (error) {
        this.error = error
      }.bind(this))
    },
    getSearchResultGroups(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('groups?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.groups = response.data
        } else {
          this.groupsNotifications = response.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultCustomers(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('customers?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.customers = response.data.data
        } else {
          this.customersNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    getSearchResultChecklists(val, notifications = false) {
      if (this.cancelHTTP) {
        this.cancelHTTP()
      }
      HTTP.get('checklists?q=' + val, {
        cancelToken: new axios.CancelToken(function executor(c) {
          this.cancelHTTP = c
        }.bind(this))
      }).then(function (response) {
        if (!notifications) {
          this.checklists = response.data.data
        } else {
          this.checklistsNotifications = response.data.data
        }
      }.bind(this)).catch(function (error) {
        this.errorCancel = error
      }.bind(this))
    },
    selectItem(type, item, notifications = false) {
      if (!notifications) {
        this.data.assigned[type].push(item)
        this.searchGroups = ''
        this.searchCustomers = ''
        this.searchChecklists = ''
        this.checkDuplicates(type)
      } else {
        this.data.notifications[type].push(item)
        this.searchGroupsNotifications = ''
        this.searchCustomersNotifications = ''
        this.searchChecklistsNotifications = ''
        this.checkDuplicates(type, true)
      }
    },
    isAdmin() {
      return this.loginUser && this.loginUser.role === "admin"
    },
    removeItem(type, index, notifications = false) {
      if (notifications) {
        this.data.notifications[type].splice(index, 1)
      } else {
        this.data.assigned[type].splice(index, 1)
      }
    },
    checkDuplicates(type, notifications = false) {
      if (notifications) {
        this.data.notifications[type] = _.uniqBy(this.data.notifications[type], 'id')
      } else {
        this.data.assigned[type] = _.uniqBy(this.data.assigned[type], 'id')
      }
    },
    inArray(needle, haystack) {
      var length = haystack.length
      for (var i = 0; i < length; i++) {
        if (haystack[i] === needle) {
          return true
        }
      }
      return false
    },
    close() {
      this.$router.go(-1);
    },
    cancel() {
      this.close();
    },
    parseQueryParams(url) {
      const params = {}
      const queryString = url.split('?')[1];

      if (queryString) {
        const keyValuePairs = queryString.split('&');

        keyValuePairs.forEach(keyValuePair => {
          const [key, value] = keyValuePair.split('=');
          const decodedKey = decodeURIComponent(key);
          const decodedValue = decodeURIComponent(value || '');
          params[decodedKey] = decodedValue;
        });
      }

      return params;
    },
    saveItem() {
      let path = 'users';

      const queryParams = this.parseQueryParams(window.location.search);

      if (typeof queryParams.customer_id !== "undefined") {
        path += "?customer_id=" + queryParams.customer_id;
      }

      if (this.data.manager) {
        this.data.manager_id = this.data.manager.id
      }
      return HTTP.post(path, this.data);
    },
  }
}
</script>

<style>
.sp-app .user-roles .md-radio .md-radio-label {
  max-width: unset;
}

.cropper-crop-box:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 100%;
  border: 1px solid rgba(51, 153, 255, 0.75);
  pointer-events: none;
}
</style>

<style lang="scss" scoped>
#fileUpload {
  display: none;
}

.cropper-area {
  margin-bottom: 20px;
}

.preview-area {
  width: 50%;
  max-width: 400px;
  margin-bottom: 20px;
}

.preview {
  width: 100% !important;
  height: 0;
  padding-bottom: 100%;
  border-radius: 100%;
  overflow: hidden;
  border: 1px solid rgba(227, 227, 227, 0.7);
}

.preview img {
  width: 100% !important;
  height: 100% !important;
}

.cropped-image img {
  max-width: 100%;
}
</style>
