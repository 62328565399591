var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicNewContainer',{ref:"editor",attrs:{"title":_vm.t('create.title'),"saveAction":_vm.saveItem,"has-changes":_vm.hasChanges},on:{"cancel":_vm.close,"saved":_vm.onSaved},scopedSlots:_vm._u([{key:"meta",fn:function(){return [_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('category.group-title'))+"*")]),_c('v-card-text',[_c('v-select',{attrs:{"filled":"","title":_vm.t('category.hint'),"label":_vm.t('category.hint'),"items":_vm.taskCategories,"data-cy":"category_id","item-text":"name","item-value":"id","rules":[
                            function () { return !!_vm.data.category_id || _vm.$t('validation.required.category'); }
                        ]},model:{value:(_vm.data.category_id),callback:function ($$v) {_vm.$set(_vm.data, "category_id", $$v)},expression:"data.category_id"}})],1)],1),(_vm.moduleEnabled('customers'))?_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('customer-assignment.group-title')))]),_c('v-card-text',[_c('content-filter-customer',{attrs:{"get-search-url":_vm.getCustomerSearchUrl,"data-cy":"customer_id"},on:{"item-selected":_vm.customerSelected}})],1)],1):_vm._e(),_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('employee-assignment.group-title'))+"*")]),_c('v-card-text',[_c('content-filter-employee',{attrs:{"permission-group":_vm.assigneePgs,"rules":[function (v) { return !!v || _vm.$t('validation.required.user'); }]},on:{"item-selected":_vm.userSelected}})],1)],1),_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('due-date.group-title'))+"*")]),_c('v-card-text',[_c('app-date-time-picker',{attrs:{"label":_vm.t('due-date.date'),"date-format":"dd.MM.yyyy","data-cy":"due_date","clearable":false,"rules":[function (v) { return !!v || _vm.$t('validation.required.due-date'); }],"id":'dueDate',"required":true},model:{value:(_vm.data.due_date),callback:function ($$v) {_vm.$set(_vm.data, "due_date", $$v)},expression:"data.due_date"}})],1)],1)]},proxy:true}])},[[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('details.group-title')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.t('details.title') + '*',"counter":"120","data-cy":"title","filled":"","rules":[
                            function () { return !!_vm.data.title || _vm.$t('validation.required.title'); },
                            function () { return (_vm.data.title && _vm.data.title.length <= 120) || _vm.$t('validation.length.title'); }
                        ]},model:{value:(_vm.data.title),callback:function ($$v) {_vm.$set(_vm.data, "title", $$v)},expression:"data.title"}}),_c('v-textarea',{attrs:{"label":_vm.t('details.description')+'*',"data-cy":"description","filled":"","rules":[
                            function () { return !!_vm.data.description || _vm.$t('validation.required.description'); }
                        ]},model:{value:(_vm.data.description),callback:function ($$v) {_vm.$set(_vm.data, "description", $$v)},expression:"data.description"}})],1)],1),_c('v-card',{staticClass:"sp-mar-top-3",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.t('picture.group-title')))]),_c('v-card-text',[_c('picture-upload',{model:{value:(_vm.data.picture),callback:function ($$v) {_vm.$set(_vm.data, "picture", $$v)},expression:"data.picture"}})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }