import CustomersIndex from '@/views/CustomersIndex'
import CustomersEdit from '@/views/CustomersEdit'
import CustomersProjectsIndex from '@/views/CustomersProjectsIndex'
import CustomerProjectEdit from '@/views/CustomerProjectEdit'
import CustomerProjectCreate from '@/views/CustomerProjectCreate'
import CustomersContactsIndex from '@/views/CustomersContactsIndex'
import CustomersContactsEdit from '@/views/CustomersContactsEdit'
import CustomersCreate from '@/views/CustomersCreate'

const customersRoutes = [
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersIndex,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customers/create',
    name: 'CustomersCreate',
    component: CustomersCreate,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customers/:id?',
    name: 'CustomersEdit',
    component: CustomersEdit,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customers/:id?/projects',
    name: 'CustomersProjectsIndex',
    component: CustomersProjectsIndex,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customers/:id?/users',
    name: 'CustomersContactsIndex',
    component: CustomersContactsIndex,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customers/:id?/users/:userid?',
    name: 'CustomersContactsEdit',
    component: CustomersContactsEdit,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customer-projects/:id?/create',
    name: 'CustomerProjectCreate',
    component: CustomerProjectCreate,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },
  {
    path: '/customer-projects/:id?',
    name: 'CustomerProjectEdit',
    component: CustomerProjectEdit,
    meta: {
      accessConfig: {
        moduleName: ['timelog_qr', 'customers', 'timesheet'],
        requiresAuth: true
      }
    }
  },

]

export default customersRoutes
